import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import SpecialThali from '../SpecialThali';
import SpecialThali2 from '../SpecialThali2';
import EveningSession from '../EveningSession';

const GroupPackage = () => {
  return (
    <section className="sp-cnt-blk corporate">
      <Container>
        <div className='sp-cnt-in text-center'>
          <div className='sp-cnt-in2'>
          <div className='hdg'><figure><Image src="../images/group-package-hdg.png" fluid  /></figure></div>
          <div className='session-wrap'>
            <div className='hdg2'>
            <div className='spl-thalli-hdg'>
              <h3>Special Thali <span>04:00 PM to 10:00 PM</span></h3>
            </div>
            </div>
            <div className='tab-blk'><SpecialThali /></div>
          </div>
          <div className='session-wrap eve'>
            <div className='hdg2'>
              <div className='spl-thalli-hdg'>
                <h3>Special Thali <span>10:00 AM to 02:30 PM</span></h3>
              </div>
            </div>
            <div className='tab-blk'><SpecialThali2 /></div>
         
            
          </div>          
          <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div>
            </div>
          </Row>

          </div>
        </div>
      </Container>
    </section>      
  )
}

export default GroupPackage
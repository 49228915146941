import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';

const Photoshoot = () => {
  return (
    <section className="sp-cnt-blk">
      <Container>
        <div className='sp-cnt-in photoshoot text-center'>
          <div className='sp-cnt-in2'>
          <div className='hdg'><figure><Image src="../images/pre-post-wedding.png" fluid  /></figure></div>
          {/*<div className='package-tag'>₹ 8500 <span>Five members</span></div>*/}
          <div className='pre-blk'>
          <div class="package-hdg4">₹ 10000 <span>Five members allowed with your photographer</span></div>
          <h3>Additional Head Rs 1000 Extra per person.</h3>
          <Row>
            <Col md={4}>
              <div className='item-bx'>
                <figure><Image src="../images/bridal.png" fluid  /></figure>
              </div>
            </Col>
            <Col md={4}>
              <div className='item-bx'>
                <figure><Image src="../images/wedding-couple.png" fluid  /></figure>
              </div>
            </Col>
            <Col md={4}>
              <div className='item-bx last'>
                <figure><Image src="../images/bride.png" fluid  /></figure>
              </div>
            </Col>
          </Row>
          <div className='photoshoot-descp'>
              <Row className='itinerary'>
                <Col md={6}>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-tick.png" fluid  /></aside>
                      <aside className='lab'>Emperor Thali Package Applicable.</aside>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-tick.png" fluid  /></aside>
                      <aside className='lab'>Covered area with A/C for Dress Change or for make over.</aside>
                  </div>
                </Col>      
              </Row>   
              <Row className='itinerary'>                       
                <Col md={8} className='mx-auto'>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-tick.png" fluid  /></aside>
                      <aside className='lab'>These packages include all the Programme, Cultural, Village Activities, Animal welcome Travel, Limited and Unlimited food A/C Dining.</aside>
                  </div>   
                </Col>     
              </Row>
              <div className='less-tag'><p>Note: - <span>Commercial photoshoot not allowed,One week prior reservation to be done.</span></p></div>
          </div>    
            {/*<div className='less-tag'><p>[ ₹ 6000/- For Dinner & For Ac dressing room + ₹  2500/- For Village entry ] Additional head will be charged accordingly.</p></div>*/}
          <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div> 
            </div>
          </Row>
          </div>
        </div>
        </div>
      </Container>
    </section>      
  )
}

export default Photoshoot
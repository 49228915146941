import React from 'react';
import YouTubePlayer from './YouTubePlayer';

const Home = () => {
  return (
    <div className='hme'>
       <div className='hm-video'> 
        {/* <HmVideo videoId="3DXDQ9AQKjw" />*/}
        {/* <FullScreenPlayerWithSound videoId="IqU176er5Oc" /> */}
        <YouTubePlayer />
       </div>
        {/* <div className="disclaimer">
          <div className="disclaimer-in">
            <p className="bld">Disclaimer</p>
            <p>
              The Royal Chitran Reserves the Right to Change the Price & The
              Menu without Prior Notice.
              <br />
              Saachi Choukhat Reserves the Right to Change Package & Cultural
              Activities without Prior Notice.
            </p>
          </div>
        </div> */}
    </div>
  )
}

export default Home
import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';


const AdvertiseWithUs = () => {
  return (
    <section className="sp-cnt-blk">
      <Container>
        <div className='sp-cnt-in advertise text-center'>
          <div className='sp-cnt-in2'>
          <div className='hdg'><figure><Image src="../images/advertise-hdg.png" fluid  /></figure></div>
          <Row>            
              <div className='item-bx'>
                <figure><Image src="../images/hoarding.png" fluid  /></figure>
                <p>Hoarding</p>
              </div>
              <div className='item-bx'>
                <figure><Image src="../images/cutouts.png" fluid  /></figure>
                <p>Cutouts</p>
              </div>
              <div className='item-bx'>
                <figure><Image src="../images/banners.png" fluid  /></figure>
                <p>Banners</p>
              </div>
              <div className='item-bx'>
                <figure><Image src="../images/posters.png" fluid  /></figure>
                <p>Posters</p>
              </div>
              <div className='item-bx'>
                <figure><Image src="../images/stall.png" fluid  /></figure>
                <p>Stall</p>
              </div>
              <div className='item-bx'>
                <figure><Image src="../images/pamphlets.png" fluid  /></figure>
                <p>Pamphlets Distribution</p>
              </div>
              <div className='item-bx'>
                <figure><Image src="../images/sponsored-panel.png" fluid  /></figure>
                <p>Sponsored panel on our website</p>
              </div>
              <div className='item-bx'>
                <figure><Image src="../images/coupons.png" fluid  /></figure>
                <p>On Our Disposals, Uniforms & Also On Guest Entry Coupons.</p>
              </div>
          </Row>
          <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div> 
            </div>
          </Row>
          </div>
        </div>
      </Container>
    </section>      
  )
}

export default AdvertiseWithUs
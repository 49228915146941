import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const GoogleReview = () => {


   const location = useLocation();
  // console.log('sinnamuthu',location.pathname);
  return (
    <section className="sp-cnt-blk">
      <Container>
        <div className='sp-cnt-in text-center'>
          <div className='sp-cnt-in2 no-package'>
          <div className='hdg'><figure><Image src="../images/google-review-hdg.png" fluid  /></figure></div>
          <Row className='mb20 justify-content-center align-items-center'>
            <Col lg={8} sm={8}>
              <div>
              <iframe
        width="100%"
        height="600"
        src="https://www.youtube.com/embed/R-W0dp4qbMs?autoplay=1&mute=0&controls=0&rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
              </div>
            </Col>
          </Row>            
          <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div>
              </div>
          </Row>
          </div>
        </div>
      </Container>
    </section>      
  )
}

export default GoogleReview
import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';

const ContactUs = () => {
  return (
    <section className="sp-cnt-blk contact">
      <Container>
        <div className='sp-cnt-in'>
          <div className='sp-cnt-in2 no-package'>
          <div className='hdg'><figure><Image src="../images/contact-us-hdg.png" fluid  /></figure></div>
          <Row className='mb20 justify-content-left align-items-center cont-add'>
            <Col lg={8}>
              <div className='map'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4651.705335004555!2d80.0075255!3d13.0109373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a528d84d1c19c9b%3A0x36bbe98a2c9be21a!2sThe%20RoyalChitran!5e1!3m2!1sen!2sin!4v1736597080646!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.38258398332!2d80.00340304683421!3d13.01129145468161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a528d18036b98c3%3A0x1244bfd76aeeabd8!2sChokhi%20Dhani%20Chennai!5e0!3m2!1sen!2sin!4v1694518715177!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              </div>
            </Col>
            <Col lg={4}>
              <ul className='contact'>
                <li className='add'>
                  <label>Address</label>
                  <p><b>The Royal Chitran</b>,<br />NH-4, Near Queensland Theme Park, Kevlur Road Tandalam, Sriperumbudur, Chennai, Tamil Nadu 602105</p>
                </li>
              </ul>
              <div className='btn-direction'><a href='https://maps.app.goo.gl/YU6XZcUxq39hZkD67' target='_blank'><Image src="../images/ic-direction.png" /> Direction</a></div>
                <div className='contact-wrap small justify-start'>
                  <div className='whatsapp-blk'>
                    <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
                  </div>
              </div>
            </Col>
          </Row>  
          <div className='key-information-bx'>
            <h3>Key Information</h3>
            <Row>
              <Col md={6}>
                <div className='key-in'>
                  <h4>TIMING:</h4>
                  <ul>
                    <li>We are open all 365 days from 4pm to 10pm</li>
                    <li>Dinner starts from 5.30 pm onwards</li>
                  </ul>
                </div>              
              </Col>
              <Col md={6}>
                <div className='key-in even'>
                  <h4>BOOKING AND PAYMENT</h4>
                  <ul>
                    <li>We are open all 365 days from 4pm to 10pm</li>
                    <li>Dinner starts from 5.30 pm onwards</li>
                  </ul>
                </div>              
              </Col>
            </Row>
            <div className='btn-know-more'><a href='KeyInformation'>Know More Info</a></div>
          </div>

          </div>
        </div>
      </Container>
    </section>      
  )
}

export default ContactUs
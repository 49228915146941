import React, { useState } from 'react';
import Home from "../components/pages/Home";
import GoogleReview from "../components/pages/GoogleReview";
import Packages from "../components/pages/Packages";
import GroupPackage from "../components/pages/GroupPackage";
import SchoolPicnic from '../components/pages/SchoolPicnic';
import Photoshoot from '../components/pages/Photoshoot';
import ContactUs from '../components/pages/ContactUs';
import KeyInformation from "../components/pages/KeyInformation";
import AdvertiseWithUs from "../components/pages/AdvertiseWithUs";
import Holi from "../components/pages/Holi";

const NavBarMbl = () => {
    const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);

    // Toggle a className on the body element
    document.body.classList.toggle('open');
  };

  return (
    <div>
    <div className="plus-btn-pos" onClick={handleButtonClick}>
      <div className="plus-btn">
        <div className="r1"></div>
        <div className="r2"></div>
        <div className="last"></div>
      </div>
    </div>
    <div className="menu-container">
      <div className="menu-sliders"></div>
      <div className="menu-sliders"></div>
      <div className="menu-sliders"></div>
      <div className="menu">
        <div className='logo'><a href="/"><img src="./images/logo.png" /></a></div>
        <ul>
            <li><a href='/' className='act hover-1'>Home</a></li>
            <li><a href='/GoogleReview' to={"/GoogleReview"} className='act hover-1'>Google Review</a></li>
            <li><a href='/Holi' to={"/Holi"} className='act hover-1'>Holi</a></li>
            <li><a href='/Packages' to={"/Packages"} className='act hover-1'>Packages</a></li>
            <li><a href='/GroupPackage' to={"/GroupPackage"} className='act hover-1'>Group Package</a></li>
            <li><a href='/SchoolPicnic' to={"/SchoolPicnic"} className='act'>School Picnic</a></li>
            <li><a href='/Photoshoot' to={"/Photoshoot"} className='act'>Photoshoot</a></li>
            <li><a href='/AdvertiseWithUs' to={"/AdvertiseWithUs"} className='act'>Advertise With Us</a></li>  
            <li><a href='/ContactUs' to={"/ContactUs"} className='act'>Contact Us</a></li>  
        </ul>
      </div>
    </div>
    </div>
  )
}

export default NavBarMbl
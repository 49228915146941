import React, { useEffect, useState } from 'react';

const YouTubePlayer = () => {
  const [isMuted, setIsMuted] = useState(true); // Track mute state
  const [player, setPlayer] = useState(null); // Store the YouTube player instance

  useEffect(() => {
    // Load the YouTube API script
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(script);

    // Create YouTube player once the API script is loaded
    window.onYouTubeIframeAPIReady = () => {
      const newPlayer = new window.YT.Player('player', {
        height: '',
        width: '',
        top:'0',
        videoId: 'IqU176er5Oc', // Replace with your YouTube video ID
        playerVars: {
          autoplay: 1,
          mute: 1, // Mute by default
          controls: 0,
          showinfo: 0,
          rel: 0,
        },
        events: {
          onReady: onPlayerReady,
        },
      });
      setPlayer(newPlayer);
    };

    // Cleanup the script when the component unmounts
    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  // This function is called when the YouTube player is ready
  const onPlayerReady = () => {
    // Initial mute state is set here
    if (player) {
      player.mute();
    }
  };

  // Toggle mute state
  const toggleMute = () => {
    if (player) {
      if (isMuted) {
        player.unMute();
      } else {
        player.mute();
      }
      setIsMuted(!isMuted); // Toggle mute state
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div id="player"></div> {/* YouTube player will be embedded here */}
      <button className="mute-button" onClick={toggleMute}>
        {isMuted ? 'Unmute' : 'Mute'}
      </button>
    </div>
  );
};

export default YouTubePlayer;

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import JustifiedTabs from "../JustifiedTab";

const Packages = () => {
  return (
    <section className="sp-cnt-blk">
      <Container>
          <div>
            <JustifiedTabs />
          </div>
        
      </Container>
    </section>
  );
};

export default Packages;

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import JustifiedTabs from "../JustifiedTab";

const Holi = () => {
  return (
    <section className="sp-cnt-blk pongal png-border-btm">
      <Container>
          <div>
            <div className="sp-cnt-in cul-pk text-center">
              <div className='hdg'><figure><Image src="../images/holi-hdg.png" fluid  /></figure></div>
              <div className="pongal-time">    
                <div className="ltd-fd-bx">
                <h3>Coming Soon</h3>
                </div>
              </div>
              <figure><Image src="../images/holi.png" fluid /></figure>  
            </div>	
          </div>
        <div className="pongal-btm">
          <Row>
          <Col className="" sm={6}>
            <p>Booking are not required</p>
            <p>No refund and transfer</p>
            <p>Pure veg. Non Alcoholic & Non Smoking Zone</p>
          </Col>
          <Col className="" sm={6}>
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div>
          </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Holi;

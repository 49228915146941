import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';


const SchoolPicnic = () => {
  return (
    <section className="sp-cnt-blk">
      <Container>
        <div className='sp-cnt-in school-picnic text-center'>
          <div className='sp-cnt-in2'>
          <div className='hdg'><figure><Image src="../images/school-picnic-hdg.png" fluid  /></figure></div>
          <div className='package-tag'>₹ 325 <span>per student <br />[ No age limit ]</span></div>
            <div className='saachi'><p><span>Package includes All the</span> Culturals , Live Events, Programs, Traditional Dances and All animal rides (Camel Ride, Camel Cart Ride, Horse cart Ride and bullock cart ride) with Limited food.</p></div>
            <div class="package-hdg3">Limited food Menu as follows :</div>
            <div className='ltd-menu-blk'>
              <h3>Menu</h3>
              <div className='menu-wrap'>
                <div className='tl'>Phoha</div><div className='divd'><Image src="../images/menu-div.png" fluid  /></div><div className='tl'>Jaljira</div><div className='divd'><Image src="../images/menu-div.png" fluid  /></div><div className='tl'>Soup</div><div className='divd'><Image src="../images/menu-div.png" fluid  /></div><div className='tl'>Noodles</div>
              </div>
              <div className='menu-wrap'>
                <div className='tl'>Sharbat</div><div className='divd'><Image src="../images/menu-div.png" fluid  /></div><div className='tl'>Rabri</div><div className='divd'><Image src="../images/menu-div.png" fluid  /></div><div className='tl'>Chana Chat</div><div className='divd'><Image src="../images/menu-div.png" fluid  /></div><div className='tl'>Drinking Water</div>
              </div>
            </div>
          <Row>
            <Col md={4}>
              <div className='item-bx'>
                <figure><Image src="../images/students.png" fluid  /></figure>
                <p>Every 25 students one teacher receives a complimentary entry.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className='item-bx'>
                <figure><Image src="../images/additional-teacher.png" fluid  /></figure>
                <p>Additional teachers or attainder who attend will be charged at Rs 450/- per head.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className='item-bx last'>
                <figure><Image src="../images/veg-food.png" fluid  /></figure>
                <p>Student can come with pure veg. food we will arrange AC Dining area.</p>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
           {/* <Col md={4}>
              <div className='item-bx'>
                <figure><Image src="../images/complimentry-lunch.png" fluid  /></figure>
                <p>For teachers or for attainder The RoyalChitran will provide the unlimited complimentary lunch.</p>
              </div>
            </Col> */}
            <Col md={4}>
              <div className='item-bx last2'>
                <figure><Image src="../images/balance-payment.png" fluid  /></figure>
                <p>Confirmation against advance and balance on arrival through cash / card / Bank transfer</p>            
              </div>
            </Col>
          </Row>
          <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div> 
            </div>
          </Row>
          </div>
        </div>
      </Container>
    </section>      
  )
}

export default SchoolPicnic
import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';

const KeyInformation = () => {
  return (
    <section className="sp-cnt-blk contact">
      <Container>
        <div className='sp-cnt-in'>
          <div className='sp-cnt-in2 no-package'>
          <div className='hdg'><figure><Image src="../images/key-information-hdg.png" fluid  /></figure></div>
          <div className='key-information-in'>
            <div className='key-information-blk'>
                <h3>TIMING</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>We are open all 365 days from 4pm to 10pm</li>
                      <li>Dinner starts from 5.30 pm onwards</li>
                      <li>Need Minimum of 3 hours to cover all our activities</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>BOOKING AND PAYMENT</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>We accept all major Credit Card, Debit Card and PAYTM</li>
                      <li>Booking and reservations are not needed (Below 40 PAX)</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>FOOD</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>We serve only pure Veg, Rajasthani, North and South Indian Dishes about 30 varieties with unlimited format.</li>
                      <li>Vegetarian Cake can be brought inside the Royalchitran.</li>
                    </ul>
                  </div>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>Our Artists and performers will perform special for your group on your request at their respective stage. Jain food available.</li>
                      <li>For kids and aged persons outside Veg, Snacks can be brought.</li>
                    </ul>
                  </div>
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>CHILD</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>We consider child as per Height No Age (Between 2.5ft to 3.5 ft)</li>
                      <li>Baby feeding place can be arranged</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>TRANSPORT</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>Buses from Chennai: KANCHEEPURAM AND SRIPERUMBUDUR WILL STOP AT Thandalam Bus stop which is 300 Mtrs. Distance from the Royalchitran.</li>
                      <li>Many private cabs reach the Royalchitran<br /> On return journey fast track NTL and Friends call Taxi can be booked from your end before 60 Min.</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>PARKING</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix full'>
                      <li>We have adequate parking area for all types of vehicles for free charges</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>CELEBRATION</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>We have semi covered area for all the setup for artist and for performers</li>
                      <li>Fully covered AC area for party Hall and Dining</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>WEATHER</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>Passing cloud rain will not affect our activities</li>
                      <li>Pathway made for easy access to all the area</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>WHEELCHAIR</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix full'>
                      <li>Wheelchair can be brought inside the Royalchitran for Guests.</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>TABLE CHAIR</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                     <ul className='clearfix full'>
                      <li>We also arrange Table, Chairs, Sitting arrangements in Dining area</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>SHOPPING</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                     <ul className='clearfix full'>
                      <li>We have Handicraft imitation jewellery, cloths from Rajasthan</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>COUPONS</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix'>
                      <li>Collect all your respective coupons before entry to village</li>
                      <li>Missing coupons will not be entertained</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>ACCOMMODATIONS</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                     <ul className='clearfix full'>
                      <li>We are coming up with Accommodation facilities</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>CAMERAS</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix full'>
                      <li>Only mobile camera allowed inside</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>SCHOOL PICNIC</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix full'>
                      <li>For School Picnic we need Minimum 130 students</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>NOON SESSION</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                  <ul className='clearfix full'>
                      <li>For Noon session we need minimum of 50 adults</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>RO PLANT</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix full'>
                      <li>We use RO plant drinking water for cooking and drinking purposes</li>
                    </ul>
                  </div>              
                </div>              
            </div>
            <div className='key-information-blk'>
                <h3>TIPS</h3>
                <div className='wrap'>
                  <div className='wrap-in'>
                    <ul className='clearfix full'>
                      <li>Kindly avoid giving tips inside the ROYALCHITRAN Our staff, Artist and Performer will greet you but it does not mean they are asking or Demanding TIPS.</li>
                    </ul>
                  </div>              
                </div>              
            </div>     
              <div className="contact-wrap">
                <div className='whatsapp-blk'>
                  <p>For more updates please</p>
                  <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
                </div>
              </div>
          </div>

          </div>
        </div>
      </Container>
    </section>      
  )
}

export default KeyInformation
import React, { useState } from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function JustifiedTabs() {
  const [activeKey, setActiveKey] = useState("village");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  // Update the state on window resize
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderContent = () => {
    switch (activeKey) {
      case "village":
        return <div>
			<div className="sp-cnt-in cul-pk text-center">
      <div className='star'>*Conditions apply<br />*5% Extra charges during Weekend & Hoiliday<br />*10% Extra charges during  31st Dec, 1st Jan</div>
          <div className="sp-cnt-in2">
            <div className="package-hdg3">Village  + Limited Food</div>
            <ul className="fee">
              <li>
                ₹600*<span>Per Head Adult</span>
              </li>
              <li>
                ₹475*<span>Per Head Child</span>
                <small>(2.5 FT to 3.5 FT)</small>
              </li>
            </ul>
            <div className="ltd-fd-bx">
              <h3><span className="text-left">Includes</span>Limited Food <span className="text-right">Package Menu</span>
              </h3>
            </div>
            <div className="menu-blk">
              <div className="wrap first">
                <div className="c1">Garlic Chutney Bajra Roti</div>
                <div className="c2"></div>
              </div>
              <div className="wrap">
                <div className="c1">Jaggery Butter Makka Roti</div>
                <div className="c2"></div>
              </div>
              <div className="wrap">
                <div className="c1">
                  Kadi Kachori / Potato Bonda / Chilli Bada
                </div>
                <div className="c2">Any One</div>
              </div>
              <div className="wrap">
                <div className="c1">
                  Onion Pakodi / Potato Pakodi / Dal Mug Pakodi
                </div>
                <div className="c2">Any One</div>
              </div>
              <div className="wrap">
                <div className="c1">Chana Chat</div>
                <div className="c2"></div>
              </div>
              <div className="wrap">
                <div className="c1">Spl. Banarasi Masala Tea</div>
                <div className="c2"></div>
              </div>
              <div className="wrap">
                <div className="c1">Tomato Soup</div>
                <div className="c2"></div>
              </div>
              <div className="wrap">
                <div className="c1">Spl. Jodhpuri Masala Tea</div>
                <div className="c2"></div>
              </div>
              <div className="wrap">
                <div className="c1">Jaljeera Water (or) Sharbat</div>
                <div className="c2"></div>
              </div>
            </div>
            <div className="package-hdg3">
              Complementary Cultural Limited Food
            </div>
            <Row className="mb20">
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/puppet-show.png" fluid />
                  </figure>
                  <div className="tl">Puppet Show</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/rope-balancing.png" fluid />
                  </figure>
                  <div className="tl">Rope Balancing</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/bioscope.png" fluid />
                  </figure>
                  <div className="tl">Bioscope</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/magician.png" fluid />
                  </figure>
                  <div className="tl">Magician</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/snakes-and-ladders.png" fluid />
                  </figure>
                  <div className="tl">Snake And Ladder</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/head-oil-massage.png" fluid />
                  </figure>
                  <div className="tl">Head Oil Massage</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/palm-reader.png" fluid />
                  </figure>
                  <div className="tl">Hindi Palm Reader</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/parrot-astrology.png" fluid />
                  </figure>
                  <div className="tl">Parrot Hindi Astrology</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/mehendi.png" fluid />
                  </figure>
                  <div className="tl">Mehendi</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/potter.png" fluid />
                  </figure>
                  <div className="tl">Potter</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/cultural-body-therapy.png" fluid />
                  </figure>
                  <div className="tl">Cultural Body Therapy</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/haryanvi-dance.png" fluid />
                  </figure>
                  <div className="tl">Cultural Dance</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/boating.png" fluid />
                  </figure>
                  <div className="tl">Boating</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/maze.png" fluid />
                  </figure>
                  <div className="tl">Maze</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/tool-maker.png" fluid />
                  </figure>
                  <div className="tl">Tool maker</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/shenai.png" fluid />
                  </figure>
                  <div className="tl">Shehnai</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/dummy-horse-dance.png" fluid />
                  </figure>
                  <div className="tl">Dummy Horse Dance</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/live-cooking.png" fluid />
                  </figure>
                  <div className="tl">Live Cooking</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/joker.png" fluid />
                  </figure>
                  <div className="tl">Joker</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/traditional-singer.png" fluid />
                  </figure>
                  <div className="tl">Traditional Folk Live Singer</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/temples.png" fluid />
                  </figure>
                  <div className="tl">Temples</div>
                </div>
              </Col>
            </Row>
            <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div>
            </div>
            </Row>
          </div>
        </div>		
		</div>;
      case "special":
        return <div>
			<div className="sp-cnt-in cul-pk text-center">
      <div className='star sec'>*Conditions apply<br />*open sky buffet</div>
          <div className="sp-cnt-in2">
            <div className="package-hdg3">Special Thali + Unlimited Food (additional)</div>
            <ul className="fee">
              <li>
                ₹275<span>Per Head Adult</span>
              </li>
              <li>
                ₹175<span>Per Head Child</span>
                <small>(2.5 FT to 3.5 FT)</small>
              </li>
            </ul>
            <div className="ltd-fd-bx">
              <h3><span className="text-left">Includes</span>Limited Food</h3>
                <h4>+</h4>
                <h3>Unlimited Thali <span>Non AC* Dining Menu</span></h3>
            </div>
            <div className="menu-blk">
                <h4>Roti</h4>
                <div className="wrap first">
                  <div className="c1">Ghee Roti / Plain Roti</div>
                  <div className="c2"></div>
                </div>
                <h4>Puri</h4>
                <div className="wrap first">
                  <div className="c1">Plain Puri / Palak Puri / Methi Puri</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Sabzi</h4>
                <div className="wrap first">
                  <div className="c1">
                    Matar Paneer / Kadai Paneer / Shahi Paneer / Paneer Masala
                  </div>
                  <div className="c2">Any One Weekend</div>
                </div>
                <div className="wrap">
                  <div className="c1">
                    Aloo Piyaz / Dum Aloo / Aloo Banarasi / Mix Veg
                  </div>
                  <div className="c2">Any One</div>
                </div>
                <div className="wrap">
                  <div className="c1">
                    Palak Mogar / Bhindi Piyaz / Bhindi Masala Mix Kofta / MIx
                    Veg
                  </div>
                  <div className="c2">Any One</div>
                </div>
                
                <h4>Gatta Sabzi</h4>
                <div className="wrap first">
                  <div className="c1">Dal Plain / Panchwela Dal</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Batti</h4>
                <div className="wrap first">
                  <div className="c1">Masala Batti / Plain Batti</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Rice</h4>
                <div className="wrap first">
                  <div className="c1">
                    Plain Rice / Veg Rice / Jera Rice / Pudina Rice / Lemon
                    Rice / Curd Rice / Sambar Rice
                  </div>
                  <div className="c2">Any Three</div>
                </div>
                <h4>Sweet</h4>
                <div className="wrap first">
                  <div className="c1">Churma / Gulab Churma</div>
                  <div className="c2">Any One</div>
                </div>
                <div className="wrap">
                  <div className="c1">Jalebi / Malupua</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Chutney</h4>
                <div className="wrap first">
                  <div className="c1">Pudina / Garlic</div>
                  <div className="c2"></div>
                </div>
                <h4>In Addition</h4>
                <div className="wrap first">
                  <div className="c1">
                    Pappad Fry / Papad Sada / Lemon Piece / Onion / Pickle
                  </div>
                  <div className="c2"></div>
                </div>
              </div>
              <div className="compt-bx">
                <div className='plus'>+</div>
                <div className="compt-bx-in">
                  <h3>Complimentary <span>Welcoming Travel By</span></h3>
                </div>
              </div>
              <Row className="mb20 d-flex justify-content-center align-self-center">
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/camel-ride.png" fluid />
                  </figure>
                  <div className="tl">Camel Safari</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/camel-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Camel Cart</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/horse-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Horse Cart</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx single">
                  <figure>
                    <Image src="../images/bullock-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Bullock Cart</div>
                </div>
              </Col>              
            </Row>
              {/* <div className="compt-bx clt">
                <div className='plus'>+</div>
                <div className="compt-bx-in">
                  <h3>Culturol & Village Activities</h3>
                </div>
              </div>
            <Row className="mb20">
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/puppet-show.png" fluid />
                  </figure>
                  <div className="tl">Puppet Show</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/rope-balancing.png" fluid />
                  </figure>
                  <div className="tl">Rope Balancing</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/bioscope.png" fluid />
                  </figure>
                  <div className="tl">Bioscope</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/magician.png" fluid />
                  </figure>
                  <div className="tl">Magician</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/snakes-and-ladders.png" fluid />
                  </figure>
                  <div className="tl">Snake And Ladder</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/head-oil-massage.png" fluid />
                  </figure>
                  <div className="tl">Head Oil Massage</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/palm-reader.png" fluid />
                  </figure>
                  <div className="tl">Hindi Palm Reader</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/parrot-astrology.png" fluid />
                  </figure>
                  <div className="tl">Parrot Hindi Astrology</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/mehendi.png" fluid />
                  </figure>
                  <div className="tl">Mehendi</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/potter.png" fluid />
                  </figure>
                  <div className="tl">Potter</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/cultural-body-therapy.png" fluid />
                  </figure>
                  <div className="tl">Cultural Body Therapy</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/haryanvi-dance.png" fluid />
                  </figure>
                  <div className="tl">Cultural Dance</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/boating.png" fluid />
                  </figure>
                  <div className="tl">Boating</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/maze.png" fluid />
                  </figure>
                  <div className="tl">Maze</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/tool-maker.png" fluid />
                  </figure>
                  <div className="tl">Tool maker</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/shenai.png" fluid />
                  </figure>
                  <div className="tl">Shehnai</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/dummy-horse-dance.png" fluid />
                  </figure>
                  <div className="tl">Dummy Horse Dance</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/live-cooking.png" fluid />
                  </figure>
                  <div className="tl">Live Cooking</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/joker.png" fluid />
                  </figure>
                  <div className="tl">Joker</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/traditional-singer.png" fluid />
                  </figure>
                  <div className="tl">Traditional Folk Live Singer</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/temples.png" fluid />
                  </figure>
                  <div className="tl">Temples</div>
                </div>
              </Col>
            </Row> */}
            <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div>
            </div>
            </Row>
          </div>
        </div>
		</div>;
      case "emperor":
        return <div>
			<div className="sp-cnt-in cul-pk text-center">
          <div className="sp-cnt-in2">
            <div className="package-hdg3">Emperor Thali + Unlimited Food  (additional)</div>
            <ul className="fee">
              <li>
                ₹400<span>Per Head Adult</span>
              </li>
              <li>
                ₹275<span>Per Head Child</span>
                <small>(2.5 FT to 3.5 FT)</small>
              </li>
            </ul>
            <div className="ltd-fd-bx">
              <h3><span className="text-left">Includes</span>Limited Food<span className="text-left">+ Live Jalebi</span></h3>
                <h4>+</h4>
                <h3>Emperor Thali <span>AC Dining Menu</span></h3>
            </div>
            <div className="menu-blk">
                <h4>Roti</h4>
                <div className="wrap first">
                  <div className="c1">Ghee Roti / Plain Roti</div>
                  <div className="c2"></div>
                </div>
                <h4>Puri</h4>
                <div className="wrap first">
                  <div className="c1">Plain Puri / Palak Puri / Methi Puri</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Sabzi</h4>
                <div className="wrap first">
                  <div className="c1">
                    Matar Paneer / Kadai Paneer / Shahi Paneer / Paneer Masala
                  </div>
                  <div className="c2">Any One Weekend</div>
                </div>
                <div className="wrap">
                  <div className="c1">
                    Aloo Piyaz / Dum Aloo / Aloo Banarasi / Mix Veg
                  </div>
                  <div className="c2">Any One</div>
                </div>
                <div className="wrap">
                  <div className="c1">
                    Palak Mogar / Bhindi Piyaz / Bhindi Masala Mix Kofta / MIx
                    Veg
                  </div>
                  <div className="c2">Any One</div>
                </div>
                
                <h4>Gatta Sabzi</h4>
                <div className="wrap first">
                  <div className="c1">Dal Plain / Panchwela Dal</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Batti</h4>
                <div className="wrap first">
                  <div className="c1">Masala Batti / Plain Batti</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Rice</h4>
                <div className="wrap first">
                  <div className="c1">
                    Plain Rice / Veg Rice / Jera Rice / Pudina Rice / Lemon
                    Rice / Curd Rice / Sambar Rice
                  </div>
                  <div className="c2">Any Three</div>
                </div>
                <h4>Sweet</h4>
                <div className="wrap first">
                  <div className="c1">Churma / Gulab Churma</div>
                  <div className="c2">Any One</div>
                </div>
                <div className="wrap">
                  <div className="c1">Jalebi / Malupua</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Chutney</h4>
                <div className="wrap first">
                  <div className="c1">Pudina / Garlic</div>
                  <div className="c2"></div>
                </div>
                <h4>In Addition</h4>
                <div className="wrap first">
                  <div className="c1">
                    Pappad Fry / Papad Sada / Lemon Piece / Onion / Pickle
                  </div>
                  <div className="c2"></div>
                </div>
              </div>
              <div className="compt-bx">
                <div className='plus'>+</div>
                <div className="compt-bx-in">
                  <h3>Complimentary <span>Welcoming Travel By</span></h3>
                </div>
              </div>
              <Row className="mb20 d-flex justify-content-center align-self-center">
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/camel-ride.png" fluid />
                  </figure>
                  <div className="tl">Camel Safari</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/camel-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Camel Cart</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/horse-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Horse Cart</div>
                </div>
              </Col>
              <Col className="cul-bx mb0" lg={4} sm={6}>
                <div className="item-bx single">
                  <figure>
                    <Image src="../images/bullock-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Bullock Cart</div>
                </div>
              </Col>              
            </Row>
              <div className="compt-bx clt">
                <div className='plus'>+</div>
                <div className="compt-bx-in">
                  <h3>Culturol & Village Activities</h3>
                </div>
              </div>
            <Row className="mb20">
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/puppet-show.png" fluid />
                  </figure>
                  <div className="tl">Puppet Show</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/rope-balancing.png" fluid />
                  </figure>
                  <div className="tl">Rope Balancing</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/bioscope.png" fluid />
                  </figure>
                  <div className="tl">Bioscope</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/magician.png" fluid />
                  </figure>
                  <div className="tl">Magician</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/snakes-and-ladders.png" fluid />
                  </figure>
                  <div className="tl">Snake And Ladder</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/head-oil-massage.png" fluid />
                  </figure>
                  <div className="tl">Head Oil Massage</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/palm-reader.png" fluid />
                  </figure>
                  <div className="tl">Hindi Palm Reader</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/parrot-astrology.png" fluid />
                  </figure>
                  <div className="tl">Parrot Hindi Astrology</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/mehendi.png" fluid />
                  </figure>
                  <div className="tl">Mehendi</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/potter.png" fluid />
                  </figure>
                  <div className="tl">Potter</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/cultural-body-therapy.png" fluid />
                  </figure>
                  <div className="tl">Cultural Body Therapy</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/haryanvi-dance.png" fluid />
                  </figure>
                  <div className="tl">Cultural Dance</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/boating.png" fluid />
                  </figure>
                  <div className="tl">Boating</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/maze.png" fluid />
                  </figure>
                  <div className="tl">Maze</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/tool-maker.png" fluid />
                  </figure>
                  <div className="tl">Tool maker</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/shenai.png" fluid />
                  </figure>
                  <div className="tl">Shehnai</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/dummy-horse-dance.png" fluid />
                  </figure>
                  <div className="tl">Dummy Horse Dance</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/live-cooking.png" fluid />
                  </figure>
                  <div className="tl">Live Cooking</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/joker.png" fluid />
                  </figure>
                  <div className="tl">Joker</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/traditional-singer.png" fluid />
                  </figure>
                  <div className="tl">Traditional Folk Live Singer</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/temples.png" fluid />
                  </figure>
                  <div className="tl">Temples</div>
                </div>
              </Col>
            </Row>
            <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div>
            </div>
            </Row>
          </div>
        </div>
		</div>;
      case "royal":
        return <div>
		<div className="sp-cnt-in cul-pk text-center">
    <div className='star com'>Coming Soon</div>
          <div className="sp-cnt-in2">
            <div className="package-hdg3">Royal Thali + Unlimited Food (additional)</div>
            <ul className="fee">
              <li>
                ₹700<span>Per Head Adult</span>
              </li>
              <li>
                ₹550<span>Per Head Child</span>
                <small>(2.5 FT to 3.5 FT)</small>
              </li>
            </ul>
            <div className="ltd-fd-bx">
              <h3><span className="text-left">Includes</span>Limited Food<span className="text-left">+ Live Jalebi + Ice Gola</span></h3>
                <h4>+</h4>
                <h3>Royal Thali <span>AC Dining Menu</span></h3>
            </div>
            <div className="menu-blk">
                <h4>Roti</h4>
                <div className="wrap first">
                  <div className="c1">Ghee Roti / Plain Roti</div>
                  <div className="c2"></div>
                </div>
                <h4>Puri</h4>
                <div className="wrap first">
                  <div className="c1">Plain Puri / Palak Puri / Methi Puri</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Sabzi</h4>
                <div className="wrap first">
                  <div className="c1">
                    Matar Paneer / Kadai Paneer / Shahi Paneer / Paneer Masala
                  </div>
                  <div className="c2">Any One Weekend</div>
                </div>
                <div className="wrap">
                  <div className="c1">
                    Aloo Piyaz / Dum Aloo / Aloo Banarasi / Mix Veg
                  </div>
                  <div className="c2">Any One</div>
                </div>
                <div className="wrap">
                  <div className="c1">
                    Palak Mogar / Bhindi Piyaz / Bhindi Masala Mix Kofta / MIx
                    Veg
                  </div>
                  <div className="c2">Any One</div>
                </div>
                
                <h4>Gatta Sabzi</h4>
                <div className="wrap first">
                  <div className="c1">Dal Plain / Panchwela Dal</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Batti</h4>
                <div className="wrap first">
                  <div className="c1">Masala Batti / Plain Batti</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Rice</h4>
                <div className="wrap first">
                  <div className="c1">
                    Plain Rice / Veg Rice / Jera Rice / Pudina Rice / Lemon
                    Rice / Curd Rice / Sambar Rice
                  </div>
                  <div className="c2">Any Three</div>
                </div>
                <h4>Sweet</h4>
                <div className="wrap first">
                  <div className="c1">Churma / Gulab Churma</div>
                  <div className="c2">Any One</div>
                </div>
                <div className="wrap">
                  <div className="c1">Jalebi / Malupua</div>
                  <div className="c2">Any One</div>
                </div>
                <h4>Chutney</h4>
                <div className="wrap first">
                  <div className="c1">Pudina / Garlic</div>
                  <div className="c2"></div>
                </div>
                <h4>In Addition</h4>
                <div className="wrap first">
                  <div className="c1">
                    Pappad Fry / Papad Sada / Lemon Piece / Onion / Pickle
                  </div>
                  <div className="c2"></div>
                </div>
              </div>
              <div className="compt-bx">
                <div className='plus'>+</div>
                <div className="compt-bx-in">
                  <h3>Complimentary <span>Welcoming Travel By</span></h3>
                </div>
              </div>
              <Row className="mb20 d-flex justify-content-center align-self-center">
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/elephant.png" fluid />
                  </figure>
                  <div className="tl">Elephant</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/camel-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Camel Cart</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/horse-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Horse Cart</div>
                </div>
              </Col>
              <Col className="cul-bx mb0" lg={4} sm={6}>
                <div className="item-bx single">
                  <figure>
                    <Image src="../images/camel-ride.png" fluid />
                  </figure>
                  <div className="tl">Camel Safari</div>
                </div>
              </Col> 
              <Col className="cul-bx mb0" lg={4} sm={6}>
                <div className="item-bx single">
                  <figure>
                    <Image src="../images/bullock-cart-ride.png" fluid />
                  </figure>
                  <div className="tl">Bullock Cart</div>
                </div>
              </Col>              
            </Row>
              <div className="compt-bx clt">
                <div className='plus'>+</div>
                <div className="compt-bx-in">
                  <h3>Culturol & Village Activities</h3>
                </div>
              </div>
            <Row className="mb20">
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/puppet-show.png" fluid />
                  </figure>
                  <div className="tl">Puppet Show</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/rope-balancing.png" fluid />
                  </figure>
                  <div className="tl">Rope Balancing</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/bioscope.png" fluid />
                  </figure>
                  <div className="tl">Bioscope</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/magician.png" fluid />
                  </figure>
                  <div className="tl">Magician</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/snakes-and-ladders.png" fluid />
                  </figure>
                  <div className="tl">Snake And Ladder</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/head-oil-massage.png" fluid />
                  </figure>
                  <div className="tl">Head Oil Massage</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/palm-reader.png" fluid />
                  </figure>
                  <div className="tl">Hindi Palm Reader</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/parrot-astrology.png" fluid />
                  </figure>
                  <div className="tl">Parrot Hindi Astrology</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/mehendi.png" fluid />
                  </figure>
                  <div className="tl">Mehendi</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/potter.png" fluid />
                  </figure>
                  <div className="tl">Potter</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/cultural-body-therapy.png" fluid />
                  </figure>
                  <div className="tl">Cultural Body Therapy</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/haryanvi-dance.png" fluid />
                  </figure>
                  <div className="tl">Cultural Dance</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/boating.png" fluid />
                  </figure>
                  <div className="tl">Boating</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/maze.png" fluid />
                  </figure>
                  <div className="tl">Maze</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/tool-maker.png" fluid />
                  </figure>
                  <div className="tl">Tool maker</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/shenai.png" fluid />
                  </figure>
                  <div className="tl">Shehnai</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/dummy-horse-dance.png" fluid />
                  </figure>
                  <div className="tl">Dummy Horse Dance</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/live-cooking.png" fluid />
                  </figure>
                  <div className="tl">Live Cooking</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/joker.png" fluid />
                  </figure>
                  <div className="tl">Joker</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/traditional-singer.png" fluid />
                  </figure>
                  <div className="tl">Traditional Folk Live Singer</div>
                </div>
              </Col>
              <Col className="cul-bx" lg={4} sm={6}>
                <div className="item-bx">
                  <figure>
                    <Image src="../images/temples.png" fluid />
                  </figure>
                  <div className="tl">Temples</div>
                </div>
              </Col>
            </Row>
            <Row>
            <div className="contact-wrap">
              <div className='whatsapp-blk'>
                <p>For more updates please</p>
                <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
              </div>
            </div>
            </Row>
          </div>
        </div>		
		</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      {isMobile ? (
        <select
          className="form-select mb-3"
          value={activeKey}
          onChange={(e) => setActiveKey(e.target.value)}
        >
          <option value="village">Village + Limited Food (package)</option>
          <option value="special">Special Thali + Unlimited Food  (additional)</option>
          <option value="emperor" disabled>Emperor Thali + Unlimited Food (additional)</option>
          <option value="royal" disabled>Royal Thali + Unlimited Food (additional)</option>
        </select>
      ) : (
        <Tabs
          activeKey={activeKey}
          onSelect={(key) => setActiveKey(key)}
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab eventKey="village" title="Village + Limited Food (package)" />
          <Tab eventKey="special" title="Special Thali + Unlimited Food  (additional)" />
          <Tab eventKey="emperor" title="Emperor Thali + Unlimited Food (Coming Soon)" disabled />
          <Tab eventKey="royal" title="Royal Thali + Unlimited Food (Coming Soon)" disabled />
        </Tabs>
      )}
      <div>{renderContent()}</div>
    </div>
  );
}

export default JustifiedTabs;
import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Container, Row, Col, Image } from "react-bootstrap";

const SpecialThali2 = () => {
  return (
    <Tabs defaultActiveKey="min" id="uncontrolled-tab-example" className="">
      <Tab eventKey="min" title="Minimum 40 Pax group">
        <Row className="">
              <Col md={4}>
                <div className="item-bx last">
                  <figure>
                    <Image src="../images/adult.png" fluid />
                  </figure>
                  <div className="logo">
                    <Image src="../images/head-img.png" />
                  </div>
                  <p>
                    Per Head- Adult<span>₹ 875</span>
                  </p>
                </div>
              </Col>
              <Col md={8}>
                <Row className='itinerary'>
                  <h4>These Packages Include</h4>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-rural-activities.png" fluid  /></aside>
                      <aside className='lab'>All the Programme, Cultural, Live Traditional Dances</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-ltd-food.png" fluid  /></aside>
                      <aside className='lab'>Limited Food, Special Thali, Animal Welcoming Travel, and Unlimited A/C Sit down Lunch</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-restaurant-entry.png" fluid  /></aside>
                      <aside className='lab'>A/C Party Hall Rent Free for 60 minutes ElectricityChargesextra onlyforA/C Hall.</aside>
                  </div>
                  <div className='less-tag'><p>Note: - <span>Confirmation needed at least 15 days prior to avail group Package</span></p></div>
                </Row>
              </Col>
        </Row>
      </Tab>
      <Tab eventKey="min2" title="Minimum 75 Pax group">
        <Row className="">
              <Col md={4}>
                <div className="item-bx last">
                  <figure>
                    <Image src="../images/adult.png" fluid />
                  </figure>
                  <div className="logo">
                    <Image src="../images/head-img.png" />
                  </div>
                  <p>
                    Per Head- Adult<span>₹ 850</span>
                  </p>
                </div>
              </Col>
              <Col md={8}>
                <Row className='itinerary'>
                  <h4>These Packages Include</h4>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-rural-activities.png" fluid  /></aside>
                      <aside className='lab'>All the Programme, Cultural, Live Traditional Dances</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-ltd-food.png" fluid  /></aside>
                      <aside className='lab'>Limited Food, Special Thali, Animal Welcoming Travel, and Unlimited A/C Sit down Lunch</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-restaurant-entry.png" fluid  /></aside>
                      <aside className='lab'>A/C Party Hall Rent Free for 60 minutes ElectricityChargesextra onlyforA/C Hall.</aside>
                  </div>
                  <div className='less-tag'><p>Note: - <span>Confirmation needed at least 15 days prior to avail group Package</span></p></div>
                </Row>
              </Col>
        </Row>
      </Tab>
      <Tab eventKey="min3" title="Minimum 100 Pax group">
        <Row className="">
              <Col md={4}>
                <div className="item-bx last">
                  <figure>
                    <Image src="../images/adult.png" fluid />
                  </figure>
                  <div className="logo">
                    <Image src="../images/head-img.png" />
                  </div>
                  <p>
                    Per Head- Adult<span>₹ 825</span>
                  </p>
                </div>
              </Col>
              <Col md={8}>
                <Row className='itinerary'>
                  <h4>These Packages Include</h4>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-rural-activities.png" fluid  /></aside>
                      <aside className='lab'>All the Programme, Cultural, Live Traditional Dances</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-ltd-food.png" fluid  /></aside>
                      <aside className='lab'>Limited Food, Special Thali, Animal Welcoming Travel, and Unlimited A/C Sit down Lunch</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-restaurant-entry.png" fluid  /></aside>
                      <aside className='lab'>A/C Party Hall Rent Free for 60 minutes ElectricityChargesextra onlyforA/C Hall.</aside>
                  </div>
                  <div className='less-tag'><p>Note: - <span>Confirmation needed at least 15 days prior to avail group Package</span></p></div>
                </Row>
              </Col>
        </Row>
      </Tab>
      <Tab eventKey="min4" title="Minimum 125 Pax group">
        <Row className="">
              <Col md={4}>
                <div className="item-bx last">
                  <figure>
                    <Image src="../images/adult.png" fluid />
                  </figure>
                  <div className="logo">
                    <Image src="../images/head-img.png" />
                  </div>
                  <p>
                    Per Head- Adult<span>₹ 800</span>
                  </p>
                </div>
              </Col>
              <Col md={8}>
                <Row className='itinerary'>
                  <h4>These Packages Include</h4>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-rural-activities.png" fluid  /></aside>
                      <aside className='lab'>All the Programme, Cultural, Live Traditional Dances</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-ltd-food.png" fluid  /></aside>
                      <aside className='lab'>Limited Food, Special Thali, Animal Welcoming Travel, and Unlimited A/C Sit down Lunch</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-restaurant-entry.png" fluid  /></aside>
                      <aside className='lab'>A/C Party Hall Rent Free for 60 minutes ElectricityChargesextra onlyforA/C Hall.</aside>
                  </div>
                  <div className='less-tag'><p>Note: - <span>Confirmation needed at least 15 days prior to avail group Package</span></p></div>
                </Row>
              </Col>
        </Row>
      </Tab>
      <Tab eventKey="min5" title="Minimum 150 Pax group">
        <Row className="">
              <Col md={4}>
                <div className="item-bx last">
                  <figure>
                    <Image src="../images/adult.png" fluid />
                  </figure>
                  <div className="logo">
                    <Image src="../images/head-img.png" />
                  </div>
                  <p>
                    Per Head- Adult<span>₹ 775</span>
                  </p>
                </div>
              </Col>
              <Col md={8}>
                <Row className='itinerary'>
                  <h4>These Packages Include</h4>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-rural-activities.png" fluid  /></aside>
                      <aside className='lab'>All the Programme, Cultural, Live Traditional Dances</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-ltd-food.png" fluid  /></aside>
                      <aside className='lab'>Limited Food, Special Thali, Animal Welcoming Travel, and Unlimited A/C Sit down Lunch</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-restaurant-entry.png" fluid  /></aside>
                      <aside className='lab'>A/C Party Hall Rent Free for 60 minutes ElectricityChargesextra onlyforA/C Hall.</aside>
                  </div>
                  <div className='less-tag'><p>Note: - <span>Confirmation needed at least 15 days prior to avail group Package</span></p></div>
                </Row>
              </Col>
        </Row>
      </Tab>
      <Tab eventKey="min6" title="Minimum 200 Members & Above">
        <Row className="">
              <Col md={4}>
                <div className="item-bx last">
                  <figure>
                    <Image src="../images/adult.png" fluid />
                  </figure>
                  <div className="logo">
                    <Image src="../images/head-img.png" />
                  </div>
                  <p>
                    Per Head- Adult<span>₹ 725</span>
                  </p>
                </div>
              </Col>
              <Col md={8}>
                <Row className='itinerary'>
                  <h4>These Packages Include</h4>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-rural-activities.png" fluid  /></aside>
                      <aside className='lab'>All the Programme, Cultural, Live Traditional Dances</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-ltd-food.png" fluid  /></aside>
                      <aside className='lab'>Limited Food, Special Thali, Animal Welcoming Travel, and Unlimited A/C Sit down Lunch</aside>
                  </div>
                  <div className='it-bx'>
                      <aside className='fig'><Image src="../images/ic-restaurant-entry.png" fluid  /></aside>
                      <aside className='lab'>A/C Party Hall Rent Free for 60 minutes ElectricityChargesextra onlyforA/C Hall.</aside>
                  </div>
                  <div className='less-tag'><p>Note: - <span>Confirmation needed at least 15 days prior to avail group Package</span></p></div>
                </Row>
              </Col>
        </Row>
      </Tab>
    </Tabs>
  );
};

export default SpecialThali2;
